export const arrTypePost = [
	'POST_COMMENT_CREATE', // Комментарием на пост
	'POST_COMMENT_NOTIFY_USER', // Упоминание пользователя в комментарии
	'POST_OFFER', // Предложеная новость
	'POST_CREATE', // Новый пост
];

export const arrTypeGroup = [
	'GROUP_CREATE_MODERATION', // Создание группы
	'GROUP_CREATE_ACCEPT', // Подтверждение создания группы
	'GROUP_CREATE_REJECT', // Отклонение создания группы
	'GROUP_INVITE_MODERATION', // Запрос на приглашение в группу
	'GROUP_INVITE_ACCEPT', // Подтверждение приглашения в группу
	'GROUP_INVITE_REJECT', // Отклонение приглашения в группу
	'GROUP_JOIN', // Запрос на вступление в группу
	'GROUP_JOIN_ACCEPT', // Подтверждение вступления в группу
	'GROUP_JOIN_REJECT', // Отклонение вступления в группу
	'GROUP_UNSUBSCRIBE', // Удаление  пользователя из группы
];

export const arrTypeThanks = [
	'THANKS_CREATE', // Создание Спасибо
];

export const arrTypeActivity = [
	'ACTIVITY_CANCEL_REGISTRATION_FOR_ORGANIZER', // уведомления об отмене регистрации для организаторов
	'ACTIVITY_CANCEL_MEMBER_REGISTRATION_FOR_ORGANIZER',
	'ACTIVITY_CANCEL_MEMBER_REGISTRATION',
	'ACTIVITY_REGISTRATION_DECLARER', // Участие в мероприятии как заявителя
	'ACTIVITY_REGISTRATION_MEMBER', // Участие в мероприятии как участника
	'ACTIVITY_CANCEL_REGISTRATION', // Отмена участия в мероприятии
	'ACTIVITY_UPDATE', // Изменения в мероприятии
];

export const arrTypeTrip = [
	'TRIP_CREATE', // Создание запроса на командировку
	'TRIP_CREATE_MODERATION', // Согласование созданной командировки
	'TRIP_CREATE_ACCEPT', // Командировка согласована (для заявителя)
	'TRIP_CREATE_REJECT', // Командировка не согласована (для заявителя)
	'TRIP_ON_FIX_OWNER', // Специалист отправил заявку на исправление (для заявителя)
	'TRIP_ON_FIX_MODERATOR', // Специалист отправил заявку на исправление (для согласованта)
	'TRIP_ON_FIX_COMPLETED', // Изменения в командировку внесены
	'TRIP_ON_FIX_MODERATION', // Изменения в командировку внесены отправлены на согласование
	'TRIP_COMPLETED', // Командировка выполнена
	'TRIP_CANCEL', // Заявитель отменил командировку
	'TRIP_CANCEL_MODERATION', // Заявитель отменил командировку
	'TRIP_CANCEL_ACCEPT_ADMIN', // Отмена командировки согласована (для админа)
	'TRIP_CANCEL_ACCEPT_OWNER', // Отмена командировки согласована (для заявителя)
	'TRIP_CANCEL_REJECT', // Согласовант не согласовал отмену командировки
	'TRIP_CANCEL_ADMIN_MODERATOR', // Специалист отменил командировку (для согласованта)
	'TRIP_CANCEL_ADMIN_OWNER', // Специалист отменил командировку (для заявителя)
];

export const arrTypeBusinessCard = [
	'BUSINESS_CARD_COMPLETED', // Заявка на визитки выполнена
	'BUSINESS_CARD_CANCELED', // Заявка на визитки отменена
];

export const arrTypeCongratulation = [
	'CONGRATULATION', // Создание Поздравления
];

export const arrTypePass = [
	'PASS_MODERATION', // Согласование пропуска
	'PASS_CREATE', // Создание пропуска
	'PASS_COMPLETED', // Заявка пропуск выполнена
	'PASS_CANCELED', // Заявка пропуск отменена
];

export const arrTypeStatement = [
	'STATEMENT_COMPLETED', // Справка готова
	'STATEMENT_COMPLETED_FOR_ADMIN', // для администраторов по справкам
];

export const arrTypeIT = [
	'IT_MODERATION', // ИТ
	'IT_ACCEPT', // ИТ заявка согласована
	'IT_REJECT', // ИТ заявка отклонена
	'IT_CANCELED', // ИТ заявка отменена
];

export const arrTypeEducation = [
	'EDUCATION_CREATE', // Создание заявки на обучение
	'EDUCATION_COMPLETED', // Заявка на обучение выполнена
	'EDUCATION_CANCELED', // Заявка на обучение отменена
];

export const arrTypeDoctorForAdmin = [
	'DOCTOR_CREATE_FOR_ADMIN',
	'DOCTOR_DELETE_FOR_ADMIN',
	'DOCTOR_CANCELED_FOR_ADMIN',
];

export const arrTypeDoctor = [
	'DOCTOR_CREATE', // Создание записи к врачу
	'DOCTOR_CANCELED', // Отмена записи к врачу
	'DOCTOR_DELETE', // Удаление записи к врачу
	'DOCTOR_WAIT', // Запись в лист ожидания
];

export const arrTypeRetailShifts = [
	'RETAIL_SHIFT_ACCEPT', // Пользователь забронировал заявку.
	'RETAIL_SHIFT_REJECT', // Пользователь отменил бронь.
	'RETAIL_SHIFT_CANCELED', // В ТС отменили бронь у пользователя.
	'RETAIL_SHIFT_COMPLETED', // Заявка перешла в папку “Подтвержденные”.
];

export const arrTypeMaxiClubKpp = [
	'MAXI_CLUB_KPP_WORKING', //Заявка в работе
	'MAXI_CLUB_KPP_COMPLETED', //Заявка на карту выполнена
	'MAXI_CLUB_KPP_REJECTED', // Заявка на карту отменена
];

export const arrTypeHotLine = [
	'GROUP_MESSAGE_NEW_FOR_USER', // Вы оставили обращение на горячей линии.
	'GROUP_MESSAGE_NEW_FOR_ADMIN', // для админа
	'GROUP_MESSAGE_COMPLETED', // Заявка выполнена
	'GROUP_MESSAGE_WORKING', // Заявка в работе
];

export const arrTypeVacationToApprover = [
	'VACATION_MODERATION',
	'VACATION_CONFIRMATION',
	'VACATION_REJECTED_SKD_APPROVER',
	'VACATION_REJECTED_SKD_CONFIRMER',
	'VACATION_MODERATION_CANCEL',
	'VACATION_CANCELED',
	'VACATION_REJECTED_APPROVER',
	'VACATION_REJECTED_CONFIRMER_TO_APPROVER',
	'VACATION_ON_FIX_APPROVER',
];

export const arrTypeVacationToOwner = [
	'VACATION_REJECTED',
	'VACATION_REJECTED_SKD_OWNER',
	'VACATION_ON_FIX_OWNER',
	'VACATION_COMPLETED',
	'VACATION_CONFIRMED',
	'VACATION_CONFIRMATION_OWNER',
];

export const arrTypeCourierToApprover = [
	'COURIER_MODERATION', // Запрос на согласование заявки на курьера
	'COURIER_ON_FIX_APPROVER', // Заявка на курьера отправлена на доработку. Для согаласующего
	'COURIER_MANAGER_REJECTED_APPROVER', // Заявка на курьера отклонена администратором сервиса. Для согласующего
	'COURIER_CANCELED', // Заявка на курьера отменена
];

export const arrTypeCourierToOwner = [
	'COURIER_REJECTED', // Заявка на курьера отклонена. Для заявителя
	'COURIER_CONFIRMATION_OWNER', // Заявка на курьера согласована. Для заявителя
	'COURIER_ON_FIX_OWNER', // Заявка на курьера отправлена на доработку. Для заявителя
	'COURIER_MANAGER_REJECTED_OWNER', // Заявка на курьера отклонена администратором сервиса. Для заявителя
	'COURIER_COMPLETED', // Заявка на курьера выполнена
];

export const arrTypeDevelopmentGoal = [
	'DEVELOPMENT_GOAL_MODERATION', // "ФИ отправил(а) запрос на согласование цели в личной траектории развития."
	'DEVELOPMENT_GOAL_APPROVED', // "Цель в личной траектории развития согласована."
	'DEVELOPMENT_GOAL_REJECTED', // "Согласование цели в личной траектории развития отклонено."
	'DEVELOPMENT_GOAL_COMPLETED', // "ФИ выполнил(а) цели в личной траектории развития."
	'DEVELOPMENT_GOAL_CANCELED', // "ФИ отменил(а) цели в личной траектории развития."
	'DEVELOPMENT_GOAL_IN_WORK', // "Напоминаем, что у вас остались незакрытые цели в ЛТР."
];

export const arrTypeSurvey = [
	'SURVEY_STARTED', // Начало опроса
	'SURVEY_WILL_END_SOON', // Завершение опроса
];

export const arrTypeWorkplace = [
	'WORKPLACE_REJECT_TO_OWNER', // Заявка откланена заявителем
	'WORKPLACE_CONFIRM_TO_OWNER', // Заявка согласована
	'WORKPLACE_COMPLETED_TO_OWNER', // Заявка выполнена
	'WORKPLACE_CANCEL_TO_RECIPIENT',
	'WORKPLACE_CREATE_TO_RECIPIENT', // Заявка создана для получателя
	'WORKPLACE_COMPLETED_TO_RECIPIENT', // Заявка выполнена для получателя
];

export const arrTypeWorkplaceToApprover = [
	'WORKPLACE_CREATE_TO_APPROVER', // Заявка создана для апрувера
	'WORKPLACE_COMPLETED_TO_APPROVER', // Заявка выполнена для апрувера
	'WORKPLACE_CANCEL_TO_APPROVER',
];

export const arrTypeInventoryMove = [
	'INVENTORY_MOVE_CREATED', //Перемещение инвентарного имущества
	'INVENTORY_MOVE_MODERATION_APPROVER', //Уведомление приёмщику о возврате запроса на перемещение
	'INVENTORY_MOVE_CANCELED', //Уведомление об отмене запроса на перемещение
	'INVENTORY_MOVE_EXPIRED',
	'INVENTORY_LIQUIDATE_MODERATION_APPROVER',
	'INVENTORY_LIQUIDATE_COMPLETED_APPROVER',
	'INVENTORY_LIQUIDATE_CANCEL_APPROVER',
];

export const arrTypeInventoryInfo = [
	'INVENTORY_MOVE_MODERATION_OWNER', //Уведомление заявителю о возврате запроса на перемещение
	'INVENTORY_MOVE_REJECTED', //Уведомление об отклонении запроса на перемещение
	'INVENTORY_MOVE_COMPLETED', //Уведомление о принятии запроса на перемещение
	'INVENTORY_MOVE_CANCELED_OWNER',
	'INVENTORY_MOVE_CREATED_OWNER',
	'INVENTORY_LIQUIDATE_COMPLETED',
	'INVENTORY_LIQUIDATE_CLOSED',
	'INVENTORY_LIQUIDATE_MODERATION_OWNER',
	'INVENTORY_LIQUIDATE_CANCEL_OWNER',
	'INVENTORY_LIQUIDATE_CANCEL',
	'INVENTORY_MOVE_ERROR', //Уведомление об ошибке
	'INVENTORY_LIQUIDATE_ERROR',
];
