import React from 'react';
import { Button, TypeButton, Typography } from '@maxi-innovation/ui-kit-frontend';

import { ErrorImg } from './ErrorImg';

export const ErrorNoData = () => {
	const reloadPage = () => window.location.replace(`/`);

	return (
		<div className="error-no-data__container">
			<ErrorImg />
			<img src={process.env.PUBLIC_URL + '/img/emilia.png'} alt="Техническая ошибка" />
			<Typography variant="h1" className="error-no-data__container__header">
				Ой! У нас технические неполадки
			</Typography>
			<Typography>Не грустите! Просто перезагрузите страницу</Typography>
			<Button
				variant={TypeButton.PRIMARY}
				onClick={reloadPage}
				className="error-no-data__container__btn"
			>
				Перезагрузить страницу
			</Button>
		</div>
	);
};
