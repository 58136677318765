import React from 'react';
import { NavLink } from 'react-router-dom';

import { Roles } from 'core/components';
import { hasRole } from 'core/utils';

export const AdminAside = ({ arrayRoles, closeAdminMenu }) => {
	const closeAdminMenuHandler = () => {
		closeAdminMenu();
	};

	return (
		<aside className="admin__aside">
			<Roles isView={hasRole(arrayRoles, ['ROLE_GET_LOG'])}>
				<div className="admin__menu-block">
					<span>
						<svg role="img">
							<use
								xlinkHref={
									process.env.PUBLIC_URL + '/img/sprite.svg#admin-settings'
								}
							/>
						</svg>
						Статистика
					</span>
					<ul>
						<li>
							<NavLink
								to="/admin/statistic"
								onClick={closeAdminMenu && closeAdminMenuHandler}
								children="Группы"
							/>
						</li>
						<li>
							<NavLink
								to="/admin/search-statistic"
								onClick={closeAdminMenu && closeAdminMenuHandler}
								children="Поиск"
							/>
						</li>
						<li>
							<NavLink
								to="/admin/request-statistic"
								onClick={closeAdminMenu && closeAdminMenuHandler}
								children="Заявки"
							/>
						</li>
						<li>
							<NavLink
								to="/admin/club-card-statistic"
								onClick={closeAdminMenu && closeAdminMenuHandler}
								children="Карта МаксиКлуб"
							/>
						</li>
						<li>
							<NavLink
								to="/admin/users-info"
								onClick={closeAdminMenu && closeAdminMenuHandler}
								children="Пользователи"
							/>
						</li>
					</ul>
				</div>
				<div className="admin__menu-block">
					<span>
						<svg role="img">
							<use
								xlinkHref={
									process.env.PUBLIC_URL + '/img/sprite.svg#admin-settings'
								}
							/>
						</svg>
						Логи
					</span>
					<ul>
						<Roles isView={hasRole(arrayRoles, 'ROLE_GET_LOG')}>
							<li>
								<NavLink
									to="/admin/logi"
									onClick={closeAdminMenu && closeAdminMenuHandler}
								>
									Логи с портала
								</NavLink>
							</li>
						</Roles>
						<Roles isView={hasRole(arrayRoles, 'ROLE_GET_LOG')}>
							<li>
								<NavLink
									to="/admin/ts-logs"
									onClick={closeAdminMenu && closeAdminMenuHandler}
								>
									Логи из JMS
								</NavLink>
							</li>
						</Roles>
					</ul>
				</div>
			</Roles>

			<Roles
				isView={hasRole(arrayRoles, [
					'ROLE_EDIT_USER_CLAIMS',
					'ROLE_UPDATE_COMPETENCES',
					'ROLE_UPDATE_STICKER',
					'ROLE_EMILIA',
					'ROLE_DIGEST_ADMIN',
				])}
			>
				<div className="admin__menu-block">
					<span>
						<svg role="img">
							<use
								xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#admin-prize'}
							/>
						</svg>
						Контент
					</span>

					<ul>
						<Roles isView={hasRole(arrayRoles, 'ROLE_UPDATE_COMPETENCES')}>
							<li>
								<NavLink
									to="/admin/competences"
									onClick={closeAdminMenu && closeAdminMenuHandler}
								>
									Компетенции
								</NavLink>
							</li>
						</Roles>

						<Roles isView={hasRole(arrayRoles, 'ROLE_POSTER')}>
							<li>
								<NavLink
									to="/admin/poster/birthday"
									onClick={closeAdminMenu && closeAdminMenuHandler}
								>
									Постеры ДР
								</NavLink>
							</li>
						</Roles>

						<Roles isView={hasRole(arrayRoles, 'ROLE_POSTER')}>
							<li>
								<NavLink
									to="/admin/poster/career"
									onClick={closeAdminMenu && closeAdminMenuHandler}
								>
									Постеры назначения
								</NavLink>
							</li>
						</Roles>

						<Roles isView={hasRole(arrayRoles, 'ROLE_UPDATE_STICKER')}>
							<li>
								<NavLink
									to="/admin/stickers"
									onClick={closeAdminMenu && closeAdminMenuHandler}
								>
									Стикеры
								</NavLink>
							</li>
						</Roles>

						<Roles isView={hasRole(arrayRoles, 'ROLE_EMILIA')}>
							<li>
								<NavLink
									to="/admin/emilia"
									onClick={closeAdminMenu && closeAdminMenuHandler}
								>
									Образы и фразы Эмилии
								</NavLink>
							</li>
						</Roles>

						<Roles isView={hasRole(arrayRoles, ['ROLE_DIGEST_ADMIN'])}>
							<li>
								<NavLink
									to="/admin/digest"
									onClick={closeAdminMenu && closeAdminMenuHandler}
								>
									Дайджест
								</NavLink>
							</li>
						</Roles>

						<Roles isView={hasRole(arrayRoles, ['ROLE_POSTER'])}>
							<li>
								<NavLink
									to="/admin/congratulation"
									onClick={closeAdminMenu && closeAdminMenuHandler}
								>
									Поздравление от компании
								</NavLink>
							</li>
						</Roles>
					</ul>
				</div>
			</Roles>

			<div className="admin__menu-block">
				<Roles isView={hasRole(arrayRoles, 'ROLE_EDIT_USER_ROLE_GROUP')}>
					<div className="admin__menu-block">
						<span>
							<svg role="img">
								<use
									xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#admin-pin'}
								/>
							</svg>
							Распределение прав
						</span>

						<ul>
							<li>
								<NavLink
									to="/admin/rights"
									onClick={closeAdminMenu && closeAdminMenuHandler}
								>
									Выдача прав
								</NavLink>
							</li>
						</ul>
					</div>
				</Roles>

				<Roles
					isView={hasRole(arrayRoles, [
						'ROLE_EDIT_USER_ROLE_GROUP',
						'ROLE_GET_PERSONAL_DATA_ACCEPT',
						'ROLE_EDIT_USER_CLAIMS',
						'ROLE_UPDATE_WIKI_ROLE',
						'ROLE_EDIT_SECRETARY',
					])}
				>
					<div className="admin__menu-block">
						<span>
							<svg role="img">
								<use
									xlinkHref={
										process.env.PUBLIC_URL + '/img/sprite.svg#admin-info'
									}
								/>
							</svg>
							Дополнительно
						</span>

						<ul>
							<Roles isView={hasRole(arrayRoles, 'ROLE_EDIT_USER_ROLE_GROUP')}>
								<li>
									<NavLink
										to="/admin/icons"
										onClick={closeAdminMenu && closeAdminMenuHandler}
									>
										Иконки
									</NavLink>
								</li>
							</Roles>

							<Roles isView={hasRole(arrayRoles, 'ROLE_GET_PERSONAL_DATA_ACCEPT')}>
								<li>
									<NavLink
										to="/admin/personal-accept"
										onClick={closeAdminMenu && closeAdminMenuHandler}
									>
										Согласия на обработку персональных данных
									</NavLink>
								</li>
							</Roles>

							<Roles isView={hasRole(arrayRoles, 'ROLE_UPDATE_WIKI_ROLE')}>
								<li>
									<NavLink
										to="/admin/roles-by-positions"
										onClick={closeAdminMenu && closeAdminMenuHandler}
									>
										Создание ролей пользователей
									</NavLink>
								</li>
							</Roles>

							<Roles isView={hasRole(arrayRoles, 'ROLE_EDIT_SECRETARY')}>
								<li>
									<NavLink
										to="/admin/secretary-to-department"
										onClick={closeAdminMenu && closeAdminMenuHandler}
									>
										Прикрепление секретарей к подразделениям
									</NavLink>
								</li>
							</Roles>

							{/* <Roles isView={hasRole(arrayRoles, 'ROLE_EDIT_USER_CLAIMS')}>
                                <li><NavLink to="/admin/news-moderation" onClick={closeAdminMenu && closeAdminMenuHandler}>Модерация новостей</NavLink></li>
                            </Roles> */}
						</ul>
					</div>
				</Roles>
			</div>
		</aside>
	);
};
